import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Icon from "@shared/components/Icon";
import { severityConfigs } from "@shared/enums/severitiesTypes";
import React, { Fragment } from "react";
import { useSites } from "@shared/hooks/SiteHook";
const getSeverityData = (severityValue) => {
    const severity = Object.keys(severityConfigs).find((s) => severityConfigs[s].key === severityValue) || "";
    return severityConfigs[severity];
};
const WarningBanner = () => {
    const { currentSite: site } = useSites();
    const { settings: siteSettings } = site;
    const { warningBanner } = siteSettings || {};
    const { enable = false, severity, message } = warningBanner || {};
    if (!enable) {
        return null;
    }
    return (_jsx(Fragment, { children: _jsxs(Box, { sx: {
                bgcolor: severity && getSeverityData(severity)?.backgroundColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& > *": {
                    px: 1,
                },
            }, children: [_jsx(Icon, { icon: getSeverityData(severity)?.icon, style: { fontSize: "24px", color: severity && getSeverityData(severity)?.iconColor }, title: "Bandeau d'information" }), _jsx("div", { children: message })] }) }));
};
export default WarningBanner;

import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import Typography from "@mui/material/Typography";
import React from "react";
const ArticleTitle = (props) => {
    const { id, title, anchor, ...others } = props;
    return (_createElement("div", { ...others, key: id }, title && (_jsx(Typography, { variant: "h2", id: anchor, children: title }))));
};
ArticleTitle.defaultProps = {
    id: "",
    title: "",
    anchor: "",
};
export default ArticleTitle;

import home from "@project/templates/home/home";
import dynamic from "@project/templates/dynamic/dynamic";
import agileIT from "@project/templates/agile-it/agile-it";
import articles from "@project/templates/articles/articles";
import articleDetail from "@project/templates/article-detail/article-detail";
import dynamicVitrine from "@project/templates/dynamic-vitrine/dynamic-vitrine";
import trombinoscope from "@project/templates/trombinoscope/trombinoscope";
import contact from "@project/templates/contact/contact";
import onePage from "@project/templates/one-page/one-page";
import documentation from "./documentation/documentation";
export default [
    home,
    dynamic,
    agileIT,
    articles,
    articleDetail,
    onePage,
    dynamicVitrine,
    trombinoscope,
    contact,
    documentation,
];

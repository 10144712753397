import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import React from "react";
const PresentationBlock = (props) => {
    const { id, image, imagePosition, title, subtitle, description, footnote, backgroundColor, ...others } = props;
    return (_jsxs(Grid, { container: true, spacing: 2, ...others, children: [image && imagePosition === "left" && (_jsx(Grid, { item: true, xs: 12, md: 8, children: _jsx(ImageContainer, { ratio: "", children: image }) })), _jsx(Grid, { item: true, xs: 12, md: image ? 4 : 12, sx: { display: "flex", alignItems: "center" }, children: _jsxs(Box, { sx: {
                        bgcolor: backgroundColor,
                        p: 3.75,
                        borderRadius: 6.25,
                        width: "100%",
                    }, children: [title && (_jsx(Typography, { variant: "h3", sx: { mb: 1.25 }, children: title })), subtitle && (_jsx(Typography, { variant: "h4", sx: { color: "#FFC617", fontWeight: "normal" }, children: subtitle })), description && (_jsx(Typography, { variant: "body1", sx: { my: 2, color: "text.secondary", lineHeight: 1.5 }, children: description })), footnote && (_jsx(Typography, { variant: "body1", sx: { color: "#BAB8B8" }, children: footnote }))] }) }), image && imagePosition === "right" && (_jsx(Grid, { item: true, xs: 12, md: 8, children: _jsx(ImageContainer, { ratio: "", children: image }) }))] }, id));
};
PresentationBlock.defaultProps = {
    id: "",
    image: null,
    imagePosition: "left",
    title: "",
    subtitle: "",
    description: "",
    footnote: "",
    backgroundColor: "#FFFFFF",
};
export default PresentationBlock;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import React, { useState } from "react";
const Trombinoscope = (props) => {
    const { id, image, imageHover, position, name, nickname, link, ...others } = props;
    const [currentImage, setCurrentImage] = useState(image || imageHover || undefined);
    return (_jsxs(Box, { sx: {
            borderRadius: 4,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
        }, ...others, children: [(image || imageHover) && (_jsx(Box, { sx: { maxHeight: 450, overflow: "hidden" }, onMouseOver: () => imageHover && setCurrentImage(imageHover), onMouseOut: () => image && setCurrentImage(image), children: _jsx(ImageContainer, { ratio: "", children: currentImage }) })), _jsxs(Box, { sx: { borderColor: "#F5F5F5", borderStyle: "solid", borderWidth: 2 }, children: [position && (_jsx(Box, { sx: {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            bgcolor: "#FFC617",
                            borderRadius: 2,
                            px: 2,
                            py: 0.625,
                            fontWeight: 500,
                            color: "#FFFFFF",
                            width: "65%",
                            minWidth: 180,
                            mx: "auto",
                            mt: -2,
                        }, children: position })), _jsxs(Box, { sx: {
                            textAlign: "center",
                            p: 2.5,
                        }, children: [name && (_jsx(Typography, { variant: "h4", sx: { color: "#585858" }, children: name })), nickname && (_jsx(Typography, { variant: "body1", sx: { color: "#CCCCCC", fontWeight: 500, my: 2 }, children: nickname })), link && (_jsx("a", { href: link, children: _jsx("img", { src: "/linkedin_logo.svg", alt: "Logo LinkedIn", width: 20 }) }))] })] })] }, id));
};
Trombinoscope.defaultProps = {
    id: "",
    image: null,
    imageHover: null,
    position: "",
    name: "",
    nickname: "",
    link: "",
};
export default Trombinoscope;

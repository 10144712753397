import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Box from "@mui/system/Box";
import BlockTitle from "@shared/components/contents/blockTitle/BlockTitle";
const DescriptionBlock = (props) => {
    const { id, title, text, backgroundColor, ...others } = props;
    return (_jsx(Box, { sx: {
            bgcolor: backgroundColor,
            pt: 2,
            pb: 1,
            px: 1,
            borderRadius: 1.25,
        }, ...others, children: _jsxs(Box, { sx: {
                pb: 2,
                px: 4,
                borderStyle: "solid",
                borderRadius: 1.25,
                borderTopWidth: 2,
                borderLeftWidth: 2,
                borderRightWidth: 2,
                borderBottomWidth: 4,
                borderTopColor: "#D0D0FF",
                borderLeftColor: "#D0D0FF",
                borderRightColor: "#D0D0FF",
                borderBottomColor: "#FFC617",
            }, children: [title && (_jsx(Box, { sx: {
                        mt: -2.5,
                        py: 1,
                        "& h2": {
                            bgcolor: backgroundColor,
                        },
                    }, children: _jsx(BlockTitle, { text: title }) })), text] }) }, id));
};
export default DescriptionBlock;

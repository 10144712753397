import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@shared/components/contents/button/Button";
import useEnvironment from "@shared/hooks/useEnvironment";
import { recaptchaStyled } from "@project/components/ContactForm";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import CaptchaService from "@project/services/CaptchaService";
import ContactService from "@project/services/ContactService";
import useCookieStore from "@shared/store/CookieStore";
import useAdminPageVersionStore from "@administration/store/AdminPageVersionStore";
import { useShallow } from "zustand/react/shallow";
const buttonStyled = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    pb: 3,
};
const Mailto = (props) => {
    const { text, recipient, subject, body, contentId } = props;
    const { recaptcha_site_key_invisible } = useEnvironment();
    const { currentPageVersion } = useAdminPageVersionStore(useShallow((state) => ({
        currentPageVersion: state.currentPageVersion,
    })));
    const { id: pageId } = currentPageVersion;
    const recaptchaRef = React.createRef();
    const [recaptcha, setRecaptcha] = useState(false);
    const [loader, setLoader] = useState(false);
    const { googleRecaptchaConsent } = useCookieStore((state) => ({
        googleRecaptchaConsent: state.googleRecaptchaConsent,
    }));
    const sendCandidate = async () => {
        setLoader(true);
        if (recaptcha) {
            if (window) {
                const webmail = await ContactService.getWebmail({ contentId, pageId });
                if (webmail) {
                    window.location.href = webmail;
                }
                setLoader(false);
            }
        }
        else if (!recaptcha_site_key_invisible) {
            if (recipient) {
                window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`;
            }
            setLoader(false);
        }
        else {
            recaptchaRef.current?.execute();
        }
    };
    const checkUser = async (value) => {
        const recaptchaValidated = await CaptchaService.verifyCaptcha(value);
        setRecaptcha(recaptchaValidated.success);
        if (recaptchaValidated.success) {
            setTimeout(() => {
                setRecaptcha(false);
            }, 60000);
            if (window) {
                const webmail = await ContactService.getWebmail({ contentId, pageId });
                window.location.href = webmail;
                setLoader(false);
            }
        }
        else {
            setLoader(false);
        }
    };
    return (_jsxs(Box, { sx: buttonStyled, children: [recaptcha_site_key_invisible && googleRecaptchaConsent && (_jsx(Box, { sx: recaptchaStyled, children: _jsx(ReCAPTCHA, { ref: recaptchaRef, size: "invisible", sitekey: recaptcha_site_key_invisible, onChange: (recaptchaValue) => checkUser(recaptchaValue) }) })), _jsx(Tooltip, { title: recaptcha_site_key_invisible && !googleRecaptchaConsent
                    ? 'Vous devez accepter les cookies "Google reCAPTCHA" pour utiliser cette fonctionnalité'
                    : "", children: _jsx(Box, { children: _jsxs(Button, { onClick: () => sendCandidate(), disabled: loader || (recaptcha_site_key_invisible && !googleRecaptchaConsent), style: { position: "relative" }, children: [text, loader && _jsx(CircularProgress, { size: 25, style: { position: "absolute" } })] }) }) })] }));
};
Mailto.defaultProps = {
    recipient: "",
    subject: "",
    body: "",
};
export default Mailto;

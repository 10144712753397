export const infoBlockStyled = {
    iconStyled: {
        styleOverrides: {
            root: {
                p: 2,
                bgcolor: "primary.main",
                color: "primary.main",
                fontSize: "1.5rem",
                alignItems: "center",
            },
        },
    },
};

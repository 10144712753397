import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import PageTitle from "@project/components/PageTitle";
import BlockTitle from "@shared/components/contents/blockTitle/BlockTitle";
const ContactTemplate = (props) => {
    const { page } = props;
    const { contents, title } = page;
    const { dynamicPart, dynamicFormPart } = contents;
    return (_jsxs(Container, { children: [_jsx(PageTitle, { children: title }), _jsxs(Grid, { container: true, spacing: 3, justifyContent: "space-between", children: [_jsx(Grid, { item: true, md: 6, xs: 12, children: dynamicPart }), _jsx(Grid, { item: true, md: 5, xs: 12, children: _jsxs(Box, { sx: { px: { xs: 2, md: 0 } }, children: [_jsx(BlockTitle, { sx: {
                                        pt: 2,
                                        pb: 3,
                                    }, text: "\u00C9crivez nous ou postulez !" }), dynamicFormPart] }) })] })] }));
};
export default ContactTemplate;

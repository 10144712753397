import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Presentation from "@project/templates/home/Presentation";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import SubPageContext from "@shared/components/contents/subPage/SubPageContext";
import HomeSubpage from "@project/templates/home/HomeSubpage";
const HomeSubpageReverse = (props) => _jsx(HomeSubpage, { ...props, reverse: true });
const getSubTemplate = (_, index) => (index % 2 === 1 ? HomeSubpageReverse : HomeSubpage);
const HomeTemplate = (props) => {
    const { page } = props;
    const { contents } = page || {};
    const { subpages } = contents;
    return (_jsxs(Container, { children: [_jsx(Presentation, { ...props }), _jsx(Divider, { sx: { mt: 8, mb: 3, color: "primary.light" } }), _jsx(Grid, { container: true, justifyContent: "center", children: _jsx(Grid, { item: true, xs: 12, md: 10, children: _jsx(SubPageContext.Provider, { value: { getSubTemplate }, children: _jsx(_Fragment, { children: subpages }) }) }) })] }));
};
export default HomeTemplate;

import axios from "axios";
class ArticleService {
    static getRecentArticles = async ({ template, pageId, perPage, pageParent: parentId, siteId, currentPath, }) => {
        const response = await axios.get("/getRecentArticles", {
            params: { template, pageId, perPage, parentId, siteId, currentPath },
        });
        return response.data;
    };
}
export default ArticleService;

import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import Link from "@shared/components/contents/link/Link";
const sectionStyled = (reverse) => ({
    mt: 4,
    border: "1px solid",
    borderColor: "primary.light",
    borderRadius: reverse ? "0 75px" : "75px 0",
    boxShadow: "0px 4px 5px rgb(0 0 0 / 25%)",
    p: 3,
    bgcolor: "white",
});
const imageParentStyled = (reverse) => ({
    maxWidth: 550,
    borderRadius: reverse ? "0 60px" : "60px 0",
    overflow: "hidden",
    display: "block",
    padding: 0,
});
const HomeSubpage = (props) => {
    const { id, page, pageVersion, index, reverse, dynamicPart, subtitle, ...others } = props;
    const { image, title, shortDescription } = page || {};
    return (_jsx(Box, { sx: sectionStyled(reverse), ...others, children: _jsxs(Grid, { container: true, direction: reverse ? "row-reverse" : "row", justifyContent: "space-between", children: [_jsx(Grid, { item: true, xs: 12, md: 5, sx: imageParentStyled(reverse), children: _jsx(Link, { page: pageVersion, children: image ? (_jsx(ImageContainer, { ratio: 64, children: _jsx(_Fragment, { children: image }) })) : (_jsx("div", {})) }) }), _jsxs(Grid, { item: true, xs: 12, md: 6, children: [title && (_jsx(Typography, { variant: "h3", component: "h3", children: title })), subtitle && _jsx(Typography, { variant: "subtitle1", children: subtitle }), _jsx(Box, { sx: { mt: 2 }, children: shortDescription }), dynamicPart] })] }) }, id));
};
export default HomeSubpage;

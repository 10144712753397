import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import React from "react";
const ImageBannerVitrine = (props) => {
    const { page } = props;
    const { image, contents } = page;
    const { imageTitle, imageSubtitle, imageDescription } = contents;
    return (_jsx(Box, { sx: { bgcolor: "#F5F5F5" }, children: _jsx(Container, { children: _jsxs(Box, { sx: {
                    position: "relative",
                    mb: 6.25,
                }, children: [_jsx(Box, { sx: {
                            width: "100%",
                            height: { xs: 390, md: 500 },
                            overflow: "hidden",
                        }, children: image && _jsx(ImageContainer, { ratio: "", children: image }) }), _jsx(Box, { sx: {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            bgcolor: "#F5F5F5",
                            opacity: "0.7",
                        } }), _jsx(Box, { sx: {
                            position: "absolute",
                            top: "50%",
                            left: 0,
                            ml: -4,
                        }, children: _jsx("img", { src: "/logo_title.png", alt: "Logo de titre", height: 24 }) }), _jsxs(Box, { sx: {
                            position: "absolute",
                            top: { xs: "40%", md: "43%" },
                            left: 0,
                            pl: 10,
                        }, children: [_jsx(Typography, { variant: "h4", sx: { fontWeight: "normal", mb: 1.25 }, children: imageSubtitle }), _jsx(Typography, { variant: "h2", sx: { fontSize: "1.5rem", mb: 2 }, children: imageTitle }), _jsx(Typography, { variant: "body1", sx: { color: "#585858", fontWeight: 500, fontSize: "1.1rem", maxWidth: 600 }, children: imageDescription })] })] }) }) }));
};
ImageBannerVitrine.defaultProps = {
    page: {},
};
export default ImageBannerVitrine;

import { createElement as _createElement } from "react";
import React from "react";
//import customTypes from "@project/enums/contentsCustomType";
import ContentsTypes from "@shared/enums/contentsTypes";
import ArticleTitle from "@project/components/ArticleTitle";
import PresentationBox from "@project/components/PresentationBox";
import SubPage from "@shared/components/contents/subPage/SubPage";
import { formatPageData } from "@frontoffice/utils/templates/formatPageData";
import GeographicMap from "@project/components/GeographicMap";
import SubpageSearch from "@project/components/SubpageSearch";
import PresentationBlock from "@project/components/PresentationBlock/PresentationBlock";
import PresentationCard from "@project/components/PresentationCard/PresentationCard";
import Trombinoscope from "@project/components/Trombinoscope/Trombinoscope";
import BlockWithTitle from "@project/components/BlockWithTitle/BlockWithTitle";
import TextBlockWithTitle from "@project/components/TextBlockWithTitle/TextBlockWithTitle";
import DescriptionBlock from "@project/components/DescriptionBlock/DescriptionBlock";
import DescriptionCardWithLogo from "@project/components/DescriptionCardWithLogo/DescriptionCardWithLogo";
import ContactForm from "@project/components/ContactForm";
import Mailto from "@project/components/Mailto";
import Iframe from "@project/components/Iframe/Iframe";
import customTypes from "@project/enums/contentsCustomTypes.enum";
import TabsComponent from "@project/components/TabsComponent/TabsComponent";
const getDynamicComponent = ({ type, value, id, index }) => {
    switch (type) {
        case customTypes.ARTICLE_SECTION:
            return _createElement(ArticleTitle, { ...value, key: id });
        case customTypes.PRESENTATION_BOX:
            return _createElement(PresentationBox, { ...value, key: id });
        case customTypes.PRESENTATION_BLOCK:
            return _createElement(PresentationBlock, { ...value, key: id });
        case customTypes.PRESENTATION_CARD:
            return _createElement(PresentationCard, { ...value, key: id });
        case customTypes.TROMBINOSCOPE:
            return _createElement(Trombinoscope, { ...value, key: id });
        case ContentsTypes.SUBPAGE: {
            const { page } = value || {};
            const { image } = page || {};
            return (_createElement(SubPage, { ...value, 
                // eslint-disable-next-line react/prop-types
                page: { ...formatPageData(page), image }, pageVersion: page, key: id, index: index }));
        }
        case customTypes.BLOCK_WITH_TITLE:
            return _createElement(BlockWithTitle, { ...value, key: id });
        case customTypes.TEXT_BLOCK_WITH_TITLE:
            return _createElement(TextBlockWithTitle, { ...value, key: id });
        case customTypes.DESCRIPTION_BLOCK:
            return _createElement(DescriptionBlock, { ...value, key: id });
        case customTypes.DESCRIPTION_CARD_WITH_LOGO:
            return _createElement(DescriptionCardWithLogo, { ...value, key: id });
        case customTypes.TABS_COMPONENT:
            return _createElement(TabsComponent, { ...value, key: id });
        case customTypes.GEOGRAPHIC_MAP:
            return _createElement(GeographicMap, { ...value, key: id });
        case customTypes.SUBPAGE_SEARCH:
            return _createElement(SubpageSearch, { ...value, key: id });
        case customTypes.CONTACT_FORM:
            return _createElement(ContactForm, { ...value, key: id });
        case customTypes.MAILTO: {
            return _createElement(Mailto, { ...value, contentId: id, key: id });
        }
        case customTypes.IFRAME:
            return _createElement(Iframe, { ...value, key: id });
        default:
            return null;
    }
};
export default getDynamicComponent;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArticleService from "@project/services/ArticleService";
import SubpageSearchContext from "@project/components/SubpageSearchContext";
import { useLocation } from "react-router-dom";
const subpagesStyled = {
    bgcolor: "#FFFFFF",
    borderRadius: "20px",
    pt: 3,
    px: 4,
    pb: 1,
    "& h2": {
        fontWeight: 600,
        fontSize: 20,
        color: "text.secondary",
    },
    "& h3": {
        fontWeight: "bold",
        fontSize: 16,
        color: "text.secondary",
    },
};
const SubpageSearch = (props) => {
    const { id = "", maxPages = 10, perPage = "", title = "", className = "", template = "", pageParent = null, } = props;
    const { pathname } = useLocation();
    const { page: currentPage, SubPageTemplate } = useContext(SubpageSearchContext);
    const { id: pageId, page } = currentPage || {};
    const [recentPages, setRecentPages] = useState([]);
    useEffect(() => {
        const getRecentPages = async (nbPages) => {
            const newRecentPages = await ArticleService.getRecentArticles({
                template,
                pageId,
                perPage: nbPages,
                pageParent: typeof pageParent === "object"
                    ? pageParent?.page?.id
                    : pageParent || null,
                siteId: page.siteId,
                currentPath: pathname,
            });
            setRecentPages(newRecentPages);
        };
        if (pageId &&
            (template || pageParent) &&
            perPage &&
            perPage.match(/^[0-9]+$/) &&
            Number(perPage) > 0) {
            if (Number(perPage) > maxPages) {
                getRecentPages(maxPages).finally(() => {
                    console.warn(`SubpageSearch: perPage is greater than maxPages (${perPage} > ${maxPages})`);
                });
            }
            else {
                getRecentPages(perPage).finally(() => {
                    console.warn(`SubpageSearch: perPage is lower than maxPages (${perPage} < ${maxPages})`);
                });
            }
        }
        else {
            setRecentPages([]);
        }
    }, [pageId, template, pageParent, perPage, maxPages, page, pathname]);
    return (_jsx(_Fragment, { children: recentPages && recentPages.length > 0 && (_jsxs(Box, { className: className, sx: subpagesStyled, children: [title && (_jsx(Typography, { variant: "h2", sx: { mb: 2 }, children: title })), recentPages.map((recentPage) => (_jsx(SubPageTemplate, { page: recentPage }, recentPage.id)))] }, id)) }));
};
export default SubpageSearch;

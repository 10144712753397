import React, { useState, useEffect, createRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import useEnvironment from "@shared/hooks/useEnvironment";

const mapStyled = {
  "& .leaflet-container": {
    width: "100%",
    height: 480,
    borderRadius: "20px",
    zIndex: 1,
  },
  "& .leaflet-left": {
    right: 10,
    left: "auto",
  },
};

const GeographicMap = (props) => {
  const {
    id,
    name,
    latitude,
    longitude,
    initialZoom,
    address,
    city,
    postalCode,
    ...others
  } = props;

  const [mapComponents, setMapComponents] = useState({});
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  const { geoloc_url, geoloc_licence } = useEnvironment();

  useEffect(() => {
    if (window) {
      // eslint-disable-next-line global-require
      const L = require("leaflet");
      const DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [24, 36],
        iconAnchor: [12, 36],
        popupAnchor: [0, -40],
      });
      L.Marker.prototype.options.icon = DefaultIcon;

      // eslint-disable-next-line global-require
      require("leaflet/dist/leaflet.css");

      // eslint-disable-next-line global-require
      setMapComponents(require("react-leaflet"));
    }
  }, []);

  const mapRef = createRef();

  const { MapContainer, Marker, Popup, TileLayer } = mapComponents;

  const invalidateMap = () => {
    setTimeout(() => {
      if (mapRef && mapRef.current && mapRef.current.leafletElement) {
        mapRef.current.leafletElement.invalidateSize(false);
      }
    }, 0);
  };

  const location = {
    position: [Number(latitude), Number(longitude)],
    longitude,
    name,
    address,
    city,
    postalCode,
  };

  return (
    <>
      {!!MapContainer && (
        <Box sx={mapStyled}>
          <MapContainer
            key={id}
            center={location.position}
            zoom={Number(initialZoom)}
            ref={mapRef}
            zoomControl={!isMobile}
            onDragEnd={invalidateMap()}
            {...others}
          >
            {geoloc_url && (
              <TileLayer
                url={`${geoloc_url}/{z}/{x}/{y}.png`}
                attribution={geoloc_licence}
              />
            )}
            <Marker position={location.position}>
              <Popup>
                <Box
                  sx={{
                    "& p": {
                      bgcolor: "white",
                      m: "0 !important",
                    },
                  }}
                >
                  <Typography color="primary">{location.name}</Typography>
                  <Typography color="primary">{location.address}</Typography>
                  <Typography color="primary">
                    {location.postalCode} {location.city}
                  </Typography>
                </Box>
              </Popup>
            </Marker>
          </MapContainer>
        </Box>
      )}
    </>
  );
};

GeographicMap.propTypes = {
  id: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  initialZoom: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
};

GeographicMap.defaultProps = {
  id: "",
  latitude: "",
  longitude: "",
  initialZoom: "10",
  name: "",
  address: "",
  city: "",
  postalCode: "",
};

export default GeographicMap;

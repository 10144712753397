export const glossaryStyled = {
    letterLinkStyled: {
        styleOverrides: {
            root: {
                p: 3,
                bgcolor: "secondary.light",
                "&:hover": {
                    bgcolor: "secondary.main",
                },
            },
        },
    },
    letterTitleStyled: {
        styleOverrides: {
            root: {
                fontWeight: "600",
                display: "inline-block",
                pt: 1,
                color: "primary.main",
                "& > *": {
                    color: "primary.main",
                },
            },
        },
    },
    lettersStyled: {
        styleOverrides: {
            root: {
                right: 0,
                mb: { lg: 1 },
                py: { xs: 1, lg: 2 },
                bgcolor: { xs: "primary.main", lg: "#fff" },
            },
        },
    },
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import SwipeableViews from "react-swipeable-views-react-18-fix";
const TabsComponent = (props) => {
    const { tabs } = props;
    let tabsArray = tabs?.tabs || [];
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    if (!Array.isArray(tabsArray)) {
        tabsArray = [tabsArray];
    }
    return (_jsxs(Box, { children: [_jsx(Tabs, { value: value, onChange: handleChange, children: tabsArray?.map((tab) => _jsx(Tab, { label: tab.title }, tab.id)) }), _jsx(SwipeableViews, { index: value, children: tabsArray?.map((tab, index) => (_jsx(Box, { role: "tabpanel", id: `${tab.title}-${index}`, "aria-labelledby": `tab-${tab.title}-${index}`, p: 1, children: tab.children }, `${tab.title}-${index}`))) })] }));
};
export default TabsComponent;

import axios from "axios";
class ContactService {
    static postMail = async (sendForm) => {
        try {
            const response = await axios.post("/mail", sendForm);
            return response.data;
        }
        catch (err) {
            return {
                status: err.response?.status,
                message: err.response?.data?.error,
            };
        }
    };
    static getWebmail = async ({ contentId, pageId }) => {
        const response = await axios.get("/webmail", {
            params: { contentId, pageId },
        });
        return response.data;
    };
}
export default ContactService;

import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import ArticleDetailSubpage from "@project/templates/article-detail/ArticleDetailSubpage";
import SubpageSearchContext from "@project/components/SubpageSearchContext";
const imageParentStyled = {
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
    display: "block",
};
const imageTitleStyled = {
    bgcolor: "rgba(0, 4, 65, 0.6)",
    borderRadius: "0px 0px 20px 20px",
    position: "absolute",
    bottom: 0,
    width: "100%",
    "& h1": {
        color: "white",
        fontSize: "1.75rem",
    },
    "& h6": {
        fontSize: "1.1rem",
        fontWeight: 600,
        letterSpacing: "0.16em",
    },
    p: 2.5,
};
const tagStyled = {
    bgcolor: "#ecf4ff",
    color: "#00008c",
    borderRadius: "5px",
    p: 1,
    mr: 1,
    mb: 1,
    fontWeight: "600",
    fontSize: "1.2rem",
};
const ArticleDetailTemplate = (props) => {
    const { page, subpageSearch } = props;
    const { image, title, contents = {}, tags } = page || {};
    const { dynamicPart } = Array.isArray(contents) ? contents.at(0) || {} : contents;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (_jsx(Container, { children: _jsxs(Grid, { container: true, spacing: 3, children: [_jsxs(Grid, { item: true, xs: 12, md: 8, children: [_jsxs(Box, { sx: imageParentStyled, children: [image && (_jsx(ImageContainer, { ratio: isMobile ? 100 : 41, children: _jsx(_Fragment, { children: image }) })), _jsxs(Box, { sx: imageTitleStyled, children: [tags && tags.length > 0 && (_jsx(Box, { sx: { display: "flex", flexFlow: "wrap" }, children: tags.map((tag) => (_jsx(Box, { component: "span", sx: tagStyled, children: tag }, tag))) })), title && _jsx(Typography, { variant: "h1", children: title })] })] }), _jsx(Box, { sx: { mt: 3 }, children: dynamicPart })] }), _jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(SubpageSearchContext.Provider, { value: { page, SubPageTemplate: ArticleDetailSubpage }, children: _jsx(_Fragment, { children: subpageSearch }) }) })] }) }));
};
export default ArticleDetailTemplate;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Breadcrumbs, Typography, useMediaQuery } from "@mui/material";
import Link from "@shared/components/contents/link/Link";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { usePageVersion } from "@frontoffice/hooks/PageVersionHook";
const BreadcrumbItem = (props) => {
    const { item, ...others } = props;
    return (_jsx(Link, { underline: "none", color: "inherit", title: item.title, url: item.fullPath || "/", ...others, children: _jsx(Typography, { variant: "h5", color: "inherit", children: item.title }) }));
};
const Breadcrumb = () => {
    const { currentPageVersion } = usePageVersion();
    const { breadcrumb = [] } = currentPageVersion || {};
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    const parentPage = React.useMemo(() => {
        return (breadcrumb && breadcrumb.length > 1 && breadcrumb[breadcrumb.length - 2]);
    }, [breadcrumb]);
    return breadcrumb && breadcrumb.length > 1 ? (isMobile ? (_jsx(Breadcrumbs, { "aria-label": "Fil d'ariane", children: _jsx(Link, { underline: "none", color: "inherit", title: parentPage.title, url: parentPage.fullPath || "/", children: _jsx(Typography, { variant: "h5", color: "inherit", children: _jsxs(Box, { children: [_jsx(CobaltIcon, { name: "chevron-left", sx: { fontSize: 16, verticalAlign: "bottom" } }), "\u00A0", parentPage.title] }) }) }) })) : (_jsx(Breadcrumbs, { maxItems: 3, itemsAfterCollapse: 2, "aria-label": "Fil d'ariane", children: breadcrumb.map((breadcrumbItem, index) => index !== breadcrumb.length - 1 ? (_jsx(BreadcrumbItem, { item: breadcrumbItem }, breadcrumbItem.pageId)) : (_jsx(Typography, { title: breadcrumbItem.title, variant: "h5", color: "textSecondary", children: breadcrumbItem.title }, breadcrumbItem.pageId))) }))) : (_jsx(_Fragment, {}));
};
export default Breadcrumb;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Block from "@shared/components/Block";
import PageTitle from "@project/components/PageTitle";
import SubPageContext from "@shared/components/contents/subPage/SubPageContext";
import AgileItSubpage from "@project/templates/agile-it/AgileItSubpage";
const AgileItTemplate = (props) => {
    const { page } = props;
    const { shortDescription, title, contents } = page || {};
    const { subpages } = contents;
    return (_jsxs(Container, { children: [_jsxs(Grid, { item: true, sx: { mb: 3 }, children: [_jsx(PageTitle, { children: _jsx("div", { children: title }) }), _jsx(Block, { children: shortDescription })] }), _jsx(Grid, { item: true, children: _jsx(Grid, { container: true, justifyContent: "center", children: _jsx(SubPageContext.Provider, { value: { subTemplate: AgileItSubpage }, children: subpages }) }) })] }));
};
export default AgileItTemplate;

export default {
    ARTICLE_SECTION: "ARTICLE_SECTION",
    PRESENTATION_BOX: "PRESENTATION_BOX",
    PRESENTATION_BLOCK: "PRESENTATION_BLOCK",
    PRESENTATION_CARD: "PRESENTATION_CARD",
    TROMBINOSCOPE: "TROMBINOSCOPE",
    BLOCK_TITLE: "BLOCK_TITLE",
    CONTACT_FORM: "CONTACT_FORM",
    BLOCK_WITH_TITLE: "BLOCK_WITH_TITLE",
    TEXT_BLOCK_WITH_TITLE: "TEXT_BLOCK_WITH_TITLE",
    DESCRIPTION_BLOCK: "DESCRIPTION_BLOCK",
    DESCRIPTION_CARD_WITH_LOGO: "DESCRIPTION_CARD_WITH_LOGO",
    TABS_COMPONENT: "TABS_COMPONENT",
    SUB_PAGE_REDIRECT: "SUB_PAGE_REDIRECT",
    GEOGRAPHIC_MAP: "GEOGRAPHIC_MAP",
    SUBPAGE_SEARCH: "SUBPAGE_SEARCH",
    MAILTO: "MAILTO",
    IFRAME: "IFRAME",
};

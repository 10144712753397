import ContentsTypes from "@shared/enums/contentsTypes";
import HomeTemplate from "@project/templates/home/HomeTemplate";
export default {
    key: "HOME",
    label: "Accueil",
    templateImage: "/template-home.png",
    Component: HomeTemplate,
    initialContents: [
        {
            key: "subtitle",
            type: ContentsTypes.EDITABLE_INPUT,
            value: "",
        },
        {
            key: "presentationDynamic",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
        {
            key: "dynamicPartTitle",
            type: ContentsTypes.EDITABLE_INPUT,
            value: "",
        },
        {
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
        {
            key: "subpages",
            type: ContentsTypes.SUBPAGES_LIST,
            value: "",
            children: [],
        },
    ],
};

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import ReactAutosuggest from "react-autosuggest";
import { debounce } from "@shared/utils/commonUtils";
const emptySuggestion = {};
const Autosuggest = (props) => {
    const { getSuggestions, getSuggestionValue, onSuggestionSelected, renderSuggestion: renderSuggestionProps, renderInputComponent, debounce: debounceDelay, inputProps, noResult, classes, ...others } = props;
    const [suggestions, setSuggestions] = useState([]);
    const onSuggestionsFetchRequested = async ({ value }) => {
        let response = await getSuggestions(value);
        if (response && response.length === 0 && noResult) {
            response = [emptySuggestion];
        }
        setSuggestions(response);
    };
    const renderSuggestion = (suggestion) => {
        if (suggestion === emptySuggestion && noResult) {
            return noResult;
        }
        return renderSuggestionProps(suggestion);
    };
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };
    return (_jsx(ReactAutosuggest, { suggestions: suggestions, onSuggestionsFetchRequested: debounceDelay
            ? debounce(onSuggestionsFetchRequested, debounceDelay)
            : onSuggestionsFetchRequested, onSuggestionsClearRequested: onSuggestionsClearRequested, getSuggestionValue: getSuggestionValue, renderSuggestion: renderSuggestion, renderInputComponent: renderInputComponent, onSuggestionSelected: onSuggestionSelected, inputProps: inputProps, ...others }));
};
export default Autosuggest;

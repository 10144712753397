import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { getQueryParams } from "@shared/utils/urlUtils";
import InfiniteScroll from "react-infinite-scroller";
import Block from "@shared/components/Block";
import Icon from "@shared/components/Icon";
import Link from "@shared/components/contents/link/Link";
import Text from "@shared/components/contents/text/Text";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Loader from "@shared/components/Loader";
import SearchService from "@shared/services/SearchService";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useSites } from "@shared/hooks/SiteHook";
const linkStyled = {
    border: "2px solid",
    borderColor: "cobalt.bleuDigital100",
    color: "cobalt.bleuDigital100",
    borderRadius: "30px 0",
    display: "block",
    p: 2,
    "&:hover": {
        bgcolor: "cobalt.bleuDigital05",
        color: "cobalt.bleuDigital100",
    },
    "&:active": {
        bgcolor: "cobalt.bleuDigital100",
        borderColor: "cobalt.click",
        color: "cobalt.click",
    },
};
const ResultItem = ({ result }) => {
    const { _source } = result;
    const { breadcrumb, shortDescription, title } = _source || {};
    return (_jsx(Box, { sx: linkStyled, children: _jsx(Link, { page: _source, children: _jsxs(Grid, { container: true, alignItems: "center", children: [_jsxs(Grid, { item: true, sx: { flexGrow: 1 }, children: [_jsx(Typography, { variant: "h3", children: title }), _jsx(Typography, { variant: "subtitle2", children: breadcrumb
                                    .map((b) => b.title)
                                    .join(" / ") }), _jsx(Typography, { variant: "body2", component: "div", sx: { mt: 1 }, children: _jsx(Text, { sx: {
                                        "&> *": {
                                            m: 0,
                                        },
                                    }, children: shortDescription }) })] }), _jsx(Grid, { item: true, children: _jsx(Box, { component: "span", sx: { mr: 2.5, fontSize: "2.3rem", fontWeight: 300 }, children: _jsx(Icon, { icon: "long-arrow-right", type: "fal" }) }) })] }) }) }));
};
const resultPerPage = 6;
const ResultsSearch = ({ location }) => {
    const queryParams = getQueryParams(location.search);
    const { q: search } = queryParams;
    const { currentSite: site } = useSites();
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState(0);
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const loadResults = React.useCallback((page) => {
        if (page !== undefined) {
            setHasMoreResults(false);
            return SearchService.search({
                query: queryParams.q,
                from: resultPerPage * (page - 1),
                size: resultPerPage,
                siteId: site.id,
            }).then((response) => {
                const total = response?.hits?.total?.value;
                const data = response?.hits?.hits || [];
                setResults((prev) => [...prev, ...data]);
                setTotal(total);
                setHasMoreResults(data.length === resultPerPage);
            });
        }
    }, [queryParams.q, site.id]);
    useEffect(() => {
        setResults([]);
        loadResults(1);
    }, [loadResults]);
    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView({ documentTitle: "Recherche" });
    }, [search, trackPageView]);
    return (_jsxs(Container, { children: [_jsx(Block, { children: _jsxs(Typography, { variant: "h3", children: [total, " r\u00E9sultat(s) pour la recherche : \"", search, "\""] }) }), results.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Divider, { sx: { mt: 4, mb: 6 } }), _jsx(InfiniteScroll, { loadMore: loadResults, hasMore: hasMoreResults, loader: _jsx(Loader, {}, 0), pageStart: 1, style: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }, children: _jsx(Grid, { container: true, justifyContent: "space-between", spacing: 2, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, direction: "column", spacing: 2, children: results &&
                                        results.map((r) => r._source && (_jsx(Grid, { item: true, children: _jsx(ResultItem, { result: r }) }, r._source.id))) }) }) }) })] }))] }));
};
export default ResultsSearch;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Container } from "@mui/material";
import React from "react";
import BackToTop from "./BackToTop";
import Breadcrumb from "./Breadcrumb";
import Footer from "./Footer";
import Navigation from "./Navigation/Navigation";
import WarningBanner from "./WarningBanner";
import { SiteTranslationSelection } from "@shared/components/site-translation/SiteTranslationSelection";
import useGlobalStore from "@shared/store/GlobalStore";
const wrapperStyled = {
    fontFamily: "Barlow",
    bgcolor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    zIndex: 10,
};
const Layout = (props) => {
    const { children } = props;
    const { isBo } = useGlobalStore((state) => ({ isBo: state.isBo }));
    return (_jsxs(Box, { sx: wrapperStyled, children: [_jsxs(Box, { sx: { flex: "1 0 auto" }, children: [_jsx(BackToTop, {}), _jsxs(Container, { children: [_jsx(Navigation, {}), _jsx(WarningBanner, {}), !isBo && _jsx(SiteTranslationSelection, {})] }), _jsxs(Box, { sx: {
                            mt: { xs: 12, md: 0 },
                            pt: { xs: 2, md: 0 },
                        }, children: [_jsx(Container, { sx: { mb: 2 }, children: _jsx(Breadcrumb, {}) }), children] })] }), _jsx(Footer, {})] }));
};
export default Layout;

import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Icon from "@shared/components/Icon";
import Link from "@shared/components/contents/link/Link";
import React from "react";
const Social = (props) => {
    const { socialNetworks } = props;
    return (_jsx(_Fragment, { children: socialNetworks.map((social) => {
            const { id, ...others } = social.icon;
            const { id: idSocial, url, title } = social;
            if (url) {
                return (_jsx(Link, { url: url, external: true, title: title, children: _jsx(Icon, { ...others }) }, idSocial));
            }
            return _jsx(_Fragment, {});
        }) }));
};
Social.defaultProps = {
    socialNetworks: [],
};
export default Social;

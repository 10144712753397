import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, ClickAwayListener, Paper, Typography } from "@mui/material";
import Icon from "@shared/components/Icon";
import Link from "@shared/components/contents/link/Link";
import React from "react";
import { isArray } from "@shared/utils/guards";
import { usePageVersion } from "@frontoffice/hooks/PageVersionHook";
import useGlobalStore from "@shared/store/GlobalStore";
import LinkEditor from "@administration/components/editor/LinkEditor";
const styleMenuItemActive = {
    content: '""',
    position: "absolute",
    right: 11,
    bgcolor: "#FFC617",
    zIndex: -1,
    width: 10,
    height: 10,
    borderRadius: "50%",
    bottom: { xs: 10, md: 16 },
};
const styleMenuItem = {
    zIndex: 1,
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#706F6F",
    position: "relative",
    cursor: "pointer",
    p: 2,
    width: "100%",
};
const styleMenuItemLink = {
    fontFamily: '"Montserrat"',
    whiteSpace: "nowrap",
    color: "cobalt.lynch",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
};
const styleSubMenu = (theme) => ({
    position: { xs: "static", md: "absolute" },
    top: "100%",
    left: 0,
    bgcolor: { xs: "transparent", md: "white" },
    display: "flex",
    flexFlow: "column wrap",
    flexDirection: "column",
    alignItems: { xs: "center", md: "flex-start" },
    borderWidth: { xs: 0, md: "1px" },
    borderStyle: "solid",
    borderColor: "cobalt.grey10",
    [theme.breakpoints.down("md")]: {
        boxShadow: "none",
    },
});
const NavigationLink = ({ menuItem, ...others }) => {
    const { isBo } = useGlobalStore((state) => ({ isBo: state.isBo }));
    const { currentPageVersion } = usePageVersion({ enabled: !isBo });
    const { title, link = {} } = menuItem;
    const isActive = React.useMemo(() => {
        const { page } = link || {};
        const { fullPath } = page || {};
        if (currentPageVersion && fullPath) {
            if (fullPath === "/") {
                return currentPageVersion["fullPath"] === fullPath;
            }
            if (currentPageVersion["fullPath"]) {
                return currentPageVersion["fullPath"].startsWith(fullPath);
            }
        }
        return false;
    }, [link, currentPageVersion]);
    const LinkRender = isBo ? LinkEditor : Link;
    return (_jsx(Box, { ...others, children: _jsx(Box, { sx: [styleMenuItem, { "&:hover .MuiBox-root": { display: "block" } }], children: _jsxs(LinkRender, { sx: styleMenuItemLink, ...link, variant: "subtitle2", children: [title, _jsx(Box, { sx: { ...styleMenuItemActive, display: isActive ? "block" : "none" } })] }) }) }));
};
const NavigationGroup = ({ menuItem, subMenus, ...others }) => {
    const { title } = menuItem;
    const [isOpen, setIsOpen] = React.useState(false);
    const handleClickGroup = React.useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    const closeMenuGroup = React.useCallback(() => {
        setIsOpen(false);
    }, []);
    return (_jsx(Box, { ...others, children: _jsx(Box, { sx: styleMenuItem, onClick: handleClickGroup, children: _jsxs(Typography, { sx: styleMenuItemLink, variant: "subtitle2", color: "colors.lynch", component: "div", children: [title, _jsx(Icon, { icon: "caret-down", title: isOpen ? "Fermer" : "Ouvrir", style: {
                            marginLeft: 8,
                            fontSize: "1.14rem",
                            display: "flex",
                            alignItems: "center",
                            fontWeight: 600,
                            transform: `rotate(${isOpen ? 180 : 0}deg)`,
                            transition: "transform linear 200ms",
                        } }), _jsx(Box, { sx: { flexBasis: "100%" } }), isOpen && (_jsx(ClickAwayListener, { onClickAway: closeMenuGroup, touchEvent: false, children: _jsx(Paper, { sx: styleSubMenu, children: subMenus.map((item) => {
                                return (_jsx(NavigationItem, { menuItem: item, sx: {
                                        position: "relative",
                                        "&:not(:last-child):after": {
                                            content: '""',
                                            position: "absolute",
                                            height: "1px",
                                            left: 16,
                                            right: 16,
                                            bottom: 0,
                                            bgcolor: "cobalt.grey10",
                                        },
                                    } }, item.id));
                            }) }) }))] }) }) }));
};
const NavigationItem = ({ menuItem, ...others }) => {
    const subMenus = React.useMemo(() => {
        if (menuItem.subMenus) {
            return isArray(menuItem.subMenus) ? menuItem.subMenus : [menuItem.subMenus];
        }
        return [];
    }, [menuItem]);
    if (subMenus.length) {
        return _jsx(NavigationGroup, { menuItem: menuItem, subMenus: subMenus, ...others });
    }
    return _jsx(NavigationLink, { menuItem: menuItem, ...others });
};
export default NavigationItem;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useHistory, generatePath } from "react-router";
import { useCurrentPage } from "@administration/hooks/EditorPageHook";
import useGlobalStore from "@shared/store/GlobalStore";
const DescriptionCardWithLogo = (props) => {
    const { logo, title = "", description = "", redirection } = props;
    const history = useHistory();
    const { isBo } = useGlobalStore((state) => ({ isBo: state.isBo }));
    const { setCurrentPage } = useCurrentPage({ enabled: isBo });
    const handleClick = () => {
        isBo ? setCurrentPage(redirection.page) : history.push(generatePath(redirection.path));
    };
    return (_jsxs(Stack, { flexDirection: "row", sx: {
            backgroundColor: "#fff",
            px: 2,
            py: 3.5,
            width: "380px",
            borderRadius: 1,
            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
            ":hover": { cursor: "pointer" },
        }, onClick: redirection ? handleClick : undefined, children: [_jsx(Box, { sx: { width: 64, height: 64, mr: 2 }, children: logo }), _jsxs(Stack, { sx: { maxWidth: "260px" }, children: [_jsx(Typography, { variant: "h1", mb: 0.5, color: "#000000", sx: { textOverflow: "ellipsis", overflow: "hidden" }, children: title }), _jsx(Typography, { variant: "subtitle2", color: "#666D92", sx: { textOverflow: "ellipsis", overflow: "hidden" }, children: description })] })] }));
};
export default DescriptionCardWithLogo;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ImageBanner from "@project/components/ImageBanner";
import Container from "@mui/material/Container";
const DynamicTemplate = (props) => {
    const { page } = props;
    const { contents } = page;
    const { dynamicPart } = contents;
    return (_jsxs(Container, { children: [_jsx(ImageBanner, { page: page }), dynamicPart] }));
};
export default DynamicTemplate;

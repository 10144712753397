import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import smoothscroll from "smoothscroll-polyfill";
import Icon from "@shared/components/Icon";
import IconButton from "@mui/material/IconButton";
const scrollerStyled = {
    position: "fixed",
    bottom: 180,
    pointerEvents: "none",
    width: "100%",
    left: 0,
    right: 0,
    pr: "5%",
    display: { xs: "none", md: "flex" },
    justifyContent: "flex-end",
    zIndex: 1,
    boxSizing: "border-box",
    "& button": {
        pointerEvents: "auto",
    },
};
const BackToTop = () => {
    if (typeof window !== "undefined") {
        smoothscroll.polyfill();
    }
    const listener = () => {
        if (typeof document !== "undefined") {
            if (document.documentElement.scrollTop > 245) {
                document.getElementById("backToTop").style.display = "block";
            }
            else {
                document.getElementById("backToTop").style.display = "none";
            }
        }
    };
    useEffect(() => {
        if (typeof document !== "undefined") {
            window.addEventListener("scroll", listener);
            return () => {
                window.removeEventListener("scroll", listener);
            };
        }
    });
    const handleScrollUp = () => {
        if (typeof document !== "undefined") {
            window.scroll({ top: 0, left: 0, behavior: "smooth" });
        }
    };
    return (_jsx(Box, { sx: scrollerStyled, children: _jsx(IconButton, { id: "backToTop", sx: { display: "none", animation: `$fadeIn ease 700ms` }, onClick: handleScrollUp, size: "large", children: _jsx(Icon, { icon: "arrow-to-top", title: "Remonter" }) }) }));
};
export default BackToTop;

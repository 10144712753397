import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import React from "react";
const TextBlockWithTitle = (props) => {
    const { id, title, text, ...others } = props;
    return (_jsxs(Box, { sx: {
            p: 2.5,
        }, ...others, children: [title && (_jsx(Typography, { variant: "h3", sx: { color: "#D0D0FF", fontSize: "2.14rem", lineHeight: 0.8 }, children: title })), text] }, id));
};
TextBlockWithTitle.defaultProps = {
    id: "",
    title: "",
    text: "",
};
export default TextBlockWithTitle;

import ContentsTypes from "@shared/enums/contentsTypes";
import DynamicVitrineTemplate from "@project/templates/dynamic-vitrine/DynamicVitrineTemplate";
export default {
    key: "DYNAMIC_VITRINE",
    label: "Standard Vitrine",
    templateImage: "/template-dynamic-vitrine.png",
    Component: DynamicVitrineTemplate,
    initialContents: [
        {
            key: "imageSubtitle",
            type: ContentsTypes.EDITABLE_INPUT,
            value: "Soustitre",
        },
        {
            key: "imageTitle",
            type: ContentsTypes.EDITABLE_INPUT,
            value: "Titre",
        },
        {
            key: "imageDescription",
            type: ContentsTypes.EDITABLE_INPUT,
            value: "Description",
        },
        {
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
    ],
};

import contentsTypes from "@shared/enums/contentsTypes";
import ArticlesTemplate from "@project/templates/articles/ArticlesTemplate";
export default {
    key: "ARTICLES",
    label: "Blog",
    templateImage: "/template-blog.png",
    Component: ArticlesTemplate,
    initialContents: [
        {
            key: "subpages",
            type: contentsTypes.SUBPAGES_LIST,
            value: "",
            children: [],
        },
    ],
};

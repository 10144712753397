import ContentsTypes from "@shared/enums/contentsTypes";
import customTypes from "@project/enums/contentsCustomTypes.enum";
import DocumentationTemplate from "./DocumentationTemplate";
export default {
    key: "DOCUMENTATION",
    label: "Documentation",
    templateImage: "/template-documentation.png",
    useTags: true,
    Component: DocumentationTemplate,
    initialContents: [
        {
            key: "dynamicPart",
            type: ContentsTypes.DYNAMIC,
            value: "",
        },
        {
            key: "subpageSearch",
            type: customTypes.SUBPAGE_SEARCH,
            value: "",
            children: [
                {
                    key: "title",
                    type: ContentsTypes.INPUT,
                    value: "Articles récents",
                },
                {
                    key: "perPage",
                    type: ContentsTypes.INPUT_NUMBER,
                    value: "2",
                },
            ],
        },
    ],
};

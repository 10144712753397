export const textStyled = {
    styleOverrides: {
        root: {
            color: "primary.text",
            fontSize: "1rem",
            "& h2": {
                fontWeight: 600,
                fontSize: "1.75rem",
                lineHeight: "2.125rem",
                letterSpacing: "0.6px",
                mb: "1.5rem",
                mt: 0,
            },
            "& h3": {
                fontSize: "1.375rem",
                fontWeight: 600,
                lineHeight: "2.125rem",
                letterSpacing: "0px",
                mb: "1.5rem",
                mt: 0,
            },
            "& h4": {
                fontSize: "1rem",
                fontWeight: 600,
                lineHeight: "1.5rem",
                letterSpacing: "0px",
                mb: "1.5rem",
                mt: 0,
            },
            "& h5": {
                fontSize: "1.25rem",
                lineHeight: "1.625rem",
                fontWeight: 600,
            },
            "& a": {
                color: "primary.main",
                fontWeight: 600,
                textDecoration: "none",
            },
            "& pre": {
                whiteSpace: "pre-wrap",
            },
            "&  ul": {
                listStyle: "none",
                pl: 2,
                "& li": {
                    textIndent: "-16px",
                    "&:before": {
                        content: `"\\f111"`,
                        fontWeight: "600",
                        fontSize: "8px",
                        verticalAlign: "middle",
                        textIndent: "0",
                        width: "16px",
                        display: "inline-block",
                        fontFamily: `"Font Awesome 5 Pro"`,
                        color: "secondary.main",
                    },
                    "& ul": {
                        ml: 3,
                        "& li": {
                            textIndent: "-22px",
                            "&:before": {
                                content: `"\\f00c"`,
                                fontWeight: "400",
                                fontSize: "14px",
                                width: "22px",
                                color: "secondary.main",
                            },
                        },
                    },
                },
            },
        },
    },
};

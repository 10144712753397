import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Block from "@shared/components/Block";
import PageTitle from "@project/components/PageTitle";
const OnePageTemplate = (props) => {
    const { page } = props;
    const { shortDescription, title, contents } = page;
    const { dynamicPart } = contents;
    return (_jsx(Container, { children: _jsxs(Grid, { container: true, direction: "column", children: [_jsxs(Grid, { item: true, children: [_jsx(Block, { children: _jsx(PageTitle, { children: title }) }), shortDescription && shortDescription !== "" && (_jsx(Block, { children: shortDescription }))] }), _jsx(Grid, { item: true, children: _jsx("div", { children: dynamicPart }) })] }) }));
};
export default OnePageTemplate;

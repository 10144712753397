import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import React from "react";
import BlockTitle from "@shared/components/contents/blockTitle/BlockTitle";
const PresentationCard = (props) => {
    const { id = "", image, imagePosition = "left", title, description, colorText = "#343434", backgroundColor = "#FFFFFF", ...others } = props;
    return (_jsxs(Box, { sx: { backgroundColor: backgroundColor }, children: [title && _jsx(BlockTitle, { fontWeight: "bold", text: title }), _jsxs(Grid, { container: true, spacing: 2, sx: { mt: 1.5, alignItems: "center" }, ...others, children: [image && imagePosition === "left" && (_jsx(Grid, { item: true, xs: 12, md: 3, children: _jsx(ImageContainer, { ratio: "", children: image }) })), _jsx(Grid, { item: true, xs: 12, md: image ? 9 : 12, sx: { display: "flex", alignItems: "center" }, children: _jsx(Box, { sx: {
                                width: "100%",
                            }, children: description && (_jsx(Typography, { variant: "body1", sx: { color: colorText, textOverflow: "ellipsis", overflow: "hidden" }, children: description })) }) }), image && imagePosition === "right" && (_jsx(Grid, { item: true, xs: 12, md: 3, children: _jsx(ImageContainer, { ratio: "", children: image }) }))] }, id)] }));
};
export default PresentationCard;

import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import Link from "@shared/components/contents/link/Link";
const borderStyled = {
    width: "100%",
    bgcolor: "#FFFFFF",
    border: "1px solid",
    borderColor: "primary.light",
    borderRadius: "30px",
    py: 3,
    mb: 6,
    ml: 0,
};
const imageParentStyled = {
    position: "relative",
    "& > a": {
        borderRadius: "20px",
        overflow: "hidden",
        display: "block",
        mb: { xs: 2, md: 0 },
    },
};
const tagStyled = {
    bgcolor: "#ecf4ff",
    color: "#00008c",
    borderRadius: "5px",
    p: 1,
    mr: 1,
    mb: 1,
    fontWeight: "600",
    fontSize: "1.2rem",
};
const AgileItSubpage = (props) => {
    const { page, pageVersion } = props;
    const { image, title, shortDescription, tags } = page || {};
    return (_jsx(Grid, { item: true, md: 12, sx: { width: "100%" }, children: image && (_jsxs(Grid, { justifyContent: "space-around", container: true, sx: borderStyled, children: [_jsx(Grid, { item: true, md: 5, xs: 11, children: _jsx(Box, { sx: imageParentStyled, children: _jsx(Link, { page: pageVersion, children: image && (_jsx(ImageContainer, { ratio: 45, children: _jsx(_Fragment, { children: image }) })) }) }) }), _jsx(Grid, { item: true, md: 6, xs: 11, children: _jsx(Grid, { container: true, alignItems: "center", children: _jsxs(Link, { page: pageVersion, children: [tags && tags.length > 0 && (_jsx(Box, { sx: { display: "flex", flexFlow: "wrap" }, children: tags.map((tag) => (_jsx(Box, { component: "span", sx: tagStyled, children: tag }, tag))) })), title && (_jsx(Typography, { variant: "h3", sx: { fontSize: "1.75rem" }, children: title })), shortDescription && _jsx(Box, { sx: { my: 1 }, children: shortDescription })] }) }) })] })) }));
};
export default AgileItSubpage;

import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "@shared/components/Icon";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import React from "react";
const PresentationBox = (props) => {
    const { id, icon, title, subtitle, description, image, ...others } = props;
    return (_createElement("div", { ...others, key: id },
        image && (_jsx(Box, { sx: { borderRadius: "60px 0", overflow: "hidden" }, children: _jsx(ImageContainer, { ratio: 64, children: image }) })),
        icon && _jsx(Icon, { ...icon }),
        title && (_jsx(Typography, { variant: "h5", component: "h4", sx: { mb: "10px" }, children: title })),
        subtitle && (_jsx(Typography, { variant: "subtitle1", sx: { mb: "10px" }, children: subtitle })),
        description && (_jsx(Typography, { variant: "body1", sx: { color: "text.secondary", mb: "10px" }, children: description }))));
};
PresentationBox.defaultProps = {
    id: "",
    title: "",
    subtitle: "",
    description: "",
    icon: null,
    image: null,
};
export default PresentationBox;

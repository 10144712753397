import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import SubPageContext from "@shared/components/contents/subPage/SubPageContext";
import ArticleSubpage, { PrincipalArticleSubpage } from "@project/templates/articles/ArticleSubpage";
const getSubTemplate = (templateKey, index) => (index === 0 ? PrincipalArticleSubpage : ArticleSubpage);
const ArticlesTemplate = (props) => {
    const { page } = props;
    const { contents } = page || {};
    const { subpages } = contents;
    return (_jsx(Container, { children: _jsx(Grid, { container: true, justifyContent: "center", spacing: 3, children: _jsx(SubPageContext.Provider, { value: {
                    getSubTemplate,
                }, children: subpages }) }) }));
};
export default ArticlesTemplate;

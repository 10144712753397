import { jsx as _jsx } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import React from "react";
const PageTitle = (props) => {
    const { children, ...others } = props;
    return (_jsx(Typography, { variant: "h1", sx: {
            textAlign: { xs: "center", md: "left" },
            mb: 1,
        }, ...others, children: children }));
};
PageTitle.defaultProps = {
    children: null,
    style: {},
};
export default PageTitle;

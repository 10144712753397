import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@shared/components/contents/link/Link";
const NotFound = () => {
    return (_jsx(Container, { children: _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 5, children: _jsx(Box, { sx: {
                            color: "primary.dark",
                            fontWeight: "bold",
                            fontSize: { xs: "10rem", md: "18rem" },
                            textAlign: "center",
                        }, children: "404" }) }), _jsxs(Grid, { item: true, xs: 12, md: 6, container: true, direction: "column", children: [_jsx(Typography, { variant: "h2", children: "La page que vous demandez n'existe pas" }), _jsx(Link, { url: "/", children: _jsx(Typography, { variant: "subtitle1", children: "Revenir \u00E0 la page d'accueil" }) })] })] }) }));
};
export default NotFound;

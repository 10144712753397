import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Grid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import Link from "@shared/components/contents/link/Link";
const imageParentStyled = {
    position: "relative",
    "& > a": {
        borderRadius: 20,
        overflow: "hidden",
        display: "block",
    },
};
const tagStyled = {
    bgcolor: "#ecf4ff",
    color: "#00008c",
    borderRadius: "5px",
    p: 1,
    mr: 1,
    mb: 1,
    fontWeight: "600",
    fontSize: "1.2rem",
};
const ArticleSubpage = (props) => {
    const { page, pageVersion, principal } = props;
    const { image, title, shortDescription, tags } = page || {};
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, md: principal ? 12 : 4, children: _jsxs(Grid, { spacing: 3, container: true, children: [image && (_jsx(Grid, { item: true, md: principal ? 5 : 12, xs: 12, children: _jsx(Box, { sx: imageParentStyled, children: _jsx(Link, { page: pageVersion, children: image && (_jsx(ImageContainer, { ratio: 59, children: _jsx(_Fragment, { children: image }) })) }) }) })), _jsx(Grid, { item: true, md: principal ? 5 : 12, xs: 12, children: _jsxs(Link, { page: pageVersion, children: [tags && tags.length > 0 && (_jsx(Box, { sx: { display: "flex", flexFlow: "wrap" }, children: tags.map((tag) => (_jsx(Box, { component: "span", sx: tagStyled, children: tag }, tag))) })), title && (_jsx(Typography, { variant: "h3", sx: { fontSize: "1.75rem" }, children: title })), shortDescription && _jsx(Box, { sx: { my: 1 }, children: shortDescription })] }) })] }) }), principal ? (_jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, { sx: { my: 3 } }) })) : ("")] }));
};
export default ArticleSubpage;
export const PrincipalArticleSubpage = (props) => _jsx(ArticleSubpage, { ...props, principal: true });

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PageTitle from "@project/components/PageTitle";
import ImageBannerVitrine from "@project/components/ImageBannerVitrine";
import Container from "@mui/material/Container";
const DynamicVitrineTemplate = (props) => {
    const { page } = props;
    const { title, contents } = page;
    const { dynamicPart } = contents;
    return (_jsxs(_Fragment, { children: [_jsx(ImageBannerVitrine, { page: page }), _jsxs(Container, { children: [_jsx(PageTitle, { children: title }), dynamicPart] })] }));
};
export default DynamicVitrineTemplate;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ImageContainer from "@shared/components/contents/imageContainer/ImageContainer";
import Box from "@mui/system/Box";
import Container from "@mui/material/Container";
import PageTitle from "@project/components/PageTitle";
const TrombinoscopeTemplate = (props) => {
    const { page } = props;
    const { image, contents, title } = page;
    const { dynamicPart } = contents;
    return (_jsxs(_Fragment, { children: [image && (_jsx(Box, { sx: { bgcolor: "#F5F5F5" }, children: _jsx(Container, { children: _jsx(Box, { sx: {
                            position: "relative",
                            mb: 6.25,
                        }, children: _jsx(Box, { sx: {
                                width: "100%",
                                height: { xs: 390, md: 500 },
                                overflow: "hidden",
                            }, children: _jsx(ImageContainer, { ratio: "", children: image }) }) }) }) })), _jsxs(Container, { children: [_jsx(PageTitle, { children: title }), dynamicPart] })] }));
};
export default TrombinoscopeTemplate;
